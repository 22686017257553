import { faArrowLeft, faCancel, faClose, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import './Sync.css';


export class Sync extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            minimized: true,
        };
        this.handleMinimize = this.handleMinimize.bind(this);
        this.handleMaximize = this.handleMaximize.bind(this);
    }
    
    handleMinimize = () => {
        this.setState({ minimized: true });
    }
    handleMaximize = () => {
        this.setState({ minimized: false });
    }

    render() {
        return (
        <div className="Sync">
            <h1>Sync</h1>
            {(this.props.syncBuffer.length == 0)?
            <h2>Nothing to sync</h2> :
            <table className='progress-table'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Side</th>
                        <th>Progress</th>
                    </tr>
                </thead>
                <tbody>
                {this.props.syncBuffer.map((progress) => (
                    <tr key={progress}>
                        
                        <td>{progress.name} </td>
                        <td>{progress.side} </td>
                        <td> 
                        {
                            (progress.failed) ? 
                            <div className="failed">
                                <button onClick={() => this.props.onRetry(progress.id)}> <FontAwesomeIcon icon={faSync} /> Retry (failed)</button>
                            </div> :
                            <div className="progress">
                                <div className="progress-bar" style={{width: progress.progress*100+'%'}}></div>
                            </div>
                        }
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            }
            <button className="cancel" onClick={this.props.onSyncClose}>
                <FontAwesomeIcon icon={faArrowLeft} /> Back
            </button>
        </div>
        )
    }
}