import logo from './logo.svg';
import handFrame from './hand-frame.svg';
import handSurface from './hand-surface.svg';
import handMultiangle from './hand-multiangle.svg';
import React from 'react';
import './Hint.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

export function Hint(props) {
  return (
    <div className='Hint'>
      <img src={logo} className='Logo'/>
      <h1>Capture-my-hand</h1>

      <h2>How to capture your hand?</h2>
      <ol>
        <img src={handSurface} /><li>Put your hand on top of a <b>solid</b> surface <b>palm-down</b>.</li>
        <li>Make sure your hand is <b>in</b> the <b>frame</b>.</li><img src={handFrame} />
        <img src={handMultiangle} /><li>Take <b>four</b> pictures of your hand from <b>different</b> angles.</li>
      </ol>
      <button className='hint-button-ok' onClick={props.onHintOk}> <FontAwesomeIcon icon={faThumbsUp} />  Ok, I understand!</button>
    </div>
  );
}
