import React from 'react';
import './Gallery.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faArrowLeft, faTrash, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

export class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minimized: true,
    };
    this.handleMinimize = this.handleMinimize.bind(this);
    this.handleMaximize = this.handleMaximize.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.ulRef = React.createRef();
  }

  handleMinimize() {
    this.setState({ minimized: true });
  }
  handleMaximize() {
    this.setState({ minimized: false });
  }
  handleToggle() {
    this.setState({ minimized: !this.state.minimized });
  }
  handleDelete(index) {
    this.props.setImages(original => {
      const newSet = [...original.slice(0, index), ...original.slice(index + 1)];
      if (newSet.length === 0) {
        this.handleMinimize();
      }
      return newSet;
    });
  }

  handleScroll(e) {
    this.ulRef.current.scrollLeft += e.deltaY;
  }

  render() {
    

    return (
      <>
        <ul className={(this.state.minimized)?'Gallery':'Gallery-maximal'} onClick={(this.state.minimized)?this.handleMaximize:(()=>{})} dir="ltr" onWheel={this.handleScroll} ref={this.ulRef}>
          {this.props.images.map((imageSrc, index) => <li key={index}>
              <img src={imageSrc} alt="webcam" />
              <button onClick={() => this.handleDelete(index)}> <FontAwesomeIcon icon={faTrash} /></button>
              <button onClick={this.handleMinimize}> <FontAwesomeIcon icon={faAngleLeft} /> Back</button>
          </li>
          )}
        </ul>
        
      </>
    );
  }
}
