import './App.css';
import Webcam from 'react-webcam';
import React, { useCallback } from 'react';
import { ButtonCaptureContinue } from './ButtonCaptureContinue';
import { Gallery } from './Gallery';
import { SubmissionForm, submitDataWithCallbacks } from './SubmissionForm';
import axios, { Axios } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faL, faQuestionCircle, faAngleDoubleRight, faSync } from '@fortawesome/free-solid-svg-icons';
import { useWindowSize } from '@uidotdev/usehooks';
import { Hint } from './Hint';
import { Sync } from './Sync';

function App() {
  const maximages = 4;
  const api = axios.create({baseURL: process.env.API ? process.env.API : 'https://pcvaskom.fit.vutbr.cz/api'});
  const [hint, setHint] = React.useState(false);
  const [sync, setSync] = React.useState(false);
  const [deviceId, setDeviceId] = React.useState(undefined);
  const [imageList, setImageList] = React.useState([]);
  const [devices, setDevices] = React.useState([]);
  const [syncBuffer, setSyncBuffer] = React.useState([]);
  const [readyToSubmit, setReadyToSubmit] = React.useState(false);

  React.useEffect(() => {
    document.title = 'Capture my hand';
    setDeviceId(window.localStorage.getItem('deviceId') || undefined);
  }, []);


  const handleDeviceChange = React.useCallback((_deviceId) => {
      setDeviceId(_deviceId);
      window.localStorage.setItem('deviceId', _deviceId);
    }, []
  );

  const handleDevices = React.useCallback((mediaDevices => {
      const newDevices = mediaDevices.filter(({ kind }) => kind === 'videoinput');
      setDevices(newDevices);
      
      const storedDeviceId = window.localStorage.getItem('deviceId');
      const deviceIdExists = storedDeviceId !== null;
      const deviceIdExistsInNewDevices = newDevices.filter(({ _deviceId }) => _deviceId === deviceId).length > 0;
      const hasAvailableDevices = newDevices.length > 0;

      if (deviceIdExists && deviceIdExistsInNewDevices && deviceId !== storedDeviceId) {
        setDeviceId(storedDeviceId);
        return;
      } else if (hasAvailableDevices) {
        const { deviceId } = newDevices[0];
        setDeviceId(deviceId);
        window.localStorage.setItem('deviceId', deviceId);
        return;
      }
      else {
        console.log('No devices found');
      }

    }), []
  );

  const handleEnumerateDevices = React.useCallback( 
    () =>
      navigator.mediaDevices.enumerateDevices().then(handleDevices)
  );

  const handleContinue = React.useCallback(
    () =>
    {
      setReadyToSubmit(true)
    }, [setReadyToSubmit]
  )

  const handleBack = React.useCallback(
    () =>
    {
      setReadyToSubmit(false)
    }, [setReadyToSubmit]
  )

  const handleSuccess = React.useCallback(
    () => {
      setImageList([]);
      setReadyToSubmit(false);
    }, [
      setImageList,
      setReadyToSubmit,]
  );

  const handleHint = React.useCallback(
    () => {
      setHint(true);
    }, [setHint]
  );

  const handleHintCancel = React.useCallback(
    () => {
      setHint(false);
    }, [setHint]
  );

  const handleSync = React.useCallback(
    () => {
      setSync(true);
    }, [setSync]
  );

  const handleSyncCancel = React.useCallback(
    () => {
      setSync(false);
    }, [setSync]
  );

  const handleRequestRetry = React.useCallback(
    (request_id) => {
      const formData = syncBuffer.filter((request) => request.id === request_id)[0].formData;
      submitDataWithCallbacks(api, formData, request_id, setSyncBuffer);
    }, [setSyncBuffer, syncBuffer]
  );

  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImageList(prev => [...prev, imageSrc]);
    },
    [webcamRef]
  );

  const animate = syncBuffer.filter((request) => !request.failed).length > 0;
  const problem = syncBuffer.filter((request) => request.failed).length > 0;

  return (
    <>
      <>
        <div className='camera'>
          <Webcam
            videoConstraints={{
              deviceId: deviceId || window.localStorage.getItem('deviceId'),
              width: {min: 640, ideal: 3840, max: 3840},
              height: {min: 400, ideal: 2160, max: 2160},
              //aspectRatio: {exact: 1},
              frameRate: {ideal: 60},
            }}
            ref={webcamRef}
            audio={false}
            screenshotFormat="image/jpeg"
            screenshotQuality={0.95}
            forceScreenshotSourceSize={true}
            onUserMedia={handleEnumerateDevices}
          />
          
          
          <ButtonCaptureContinue 
            value={imageList.length} 
            max_value={maximages} 
            size={92} 
            thickness={8}
            space={3}
            continueCallback={handleContinue} 
            captureCallback={() => setTimeout(capture, 80)}
            fontSize={24}
            text={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            useTypeSubmit={false}
          >
            
          </ButtonCaptureContinue>
          <div className='button-bar'></div>
          
          <div className='device-list' onChange={(event) => handleDeviceChange(event.target.value)} >
            {devices.map((device, index) => <label className='container'><input type='radio' name='selectedDevice' value={device.deviceId} defaultChecked={(device.deviceId === deviceId)} /><span><FontAwesomeIcon icon={faCamera} /><sup>{index}</sup></span> </label> )}
          </div>

          <div className='hint-container'>
            <button className='hint-button' onClick={handleHint}> <FontAwesomeIcon icon={faQuestionCircle} /> Hint</button>
            <button className={'hint-button' + (animate?" animate":"") + (problem?" problem":"")} onClick={handleSync}> <FontAwesomeIcon icon={faSync} /> Sync</button>
          </div>

          {(hint)? <Hint onHintOk={handleHintCancel}/> : <></>}
          {(sync)? <Sync onSyncClose={handleSyncCancel} onRetry={handleRequestRetry} syncBuffer={syncBuffer}/> : <></>}
          

          <Gallery images={imageList} setImages={setImageList}/>
          {(readyToSubmit)?
          <>
            <SubmissionForm imageList={imageList} onSuccess={handleSuccess} api={api} handleBack={handleBack} setSyncBuffer={setSyncBuffer}/>
          </>
          : <></>}
        </div>
      </>
      
    </>
  );

}

export default App;
