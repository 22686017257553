import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import './ButtonCaptureContinue.css';

export class ButtonCaptureContinue extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let isFinished = (this.props.value === this.props.max_value);
    let callback = (isFinished) ? this.props.continueCallback : this.props.captureCallback;

    let buttonSvg = (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={this.props.size} 
      height={this.props.size} 
      viewBox={`0 0 ${this.props.size} ${this.props.size}`}
      style={{
      }}
    >
      <circle style={{
        cx: this.props.size/2,
        cy: this.props.size/2,
        r: (this.props.size-this.props.thickness)/2,
        stroke : 'rgba(255, 255, 255, 0.5)',
        fill : 'none',
        strokeWidth: this.props.thickness+'px',
      }}></circle>

      <circle
        transform={`rotate(-90 ${this.props.size/2} ${this.props.size/2})`} 
        style={{
          cx: this.props.size/2,
          cy: this.props.size/2,
          r: (this.props.size-this.props.thickness)/2,
          stroke : (this.props.value === this.props.max_value)?'rgb(50, 215, 50)':'rgb(255, 255, 255)',
          fill : 'none',
          strokeWidth: this.props.thickness+'px',
          strokeDasharray : Math.PI * (this.props.size-this.props.thickness),
          strokeDashoffset : Math.PI * (this.props.size-this.props.thickness) - (Math.PI * (this.props.size-this.props.thickness) * this.props.value) / this.props.max_value,
          transition: 'stroke-dashoffset 0.1s ease 0.1s, stroke 0.1s ease 0.1s',
        }}
      >

      </circle>

      <circle style={{
        cx: this.props.size/2,
        cy: this.props.size/2,
        r: (this.props.value === this.props.max_value)?((this.props.size)/2):((this.props.size)/2 - this.props.thickness-this.props.space),
        fill : (this.props.value === this.props.max_value)?'rgb(255, 255, 255)':'rgb(255, 255, 255)',
        transition: 'fill 0.1s ease, r 0.1s ease',
      }}></circle>

    </svg>
    )

    return (
      <>
        <button 
          onClick={callback} 
          type={(this.props.useTypeSubmit)?'submit':'button'} 
          className='ButtonCaptureContinue'
          style={{
            width: this.props.size+'px',
            height: this.props.size+'px',
          }}
        >
          {buttonSvg}
          <span hidden={this.props.value !== this.props.max_value} style={{fontSize: this.props.fontSize}}>{this.props.text}</span>
        </button>
      </>
    );
  }
}
