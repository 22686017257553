import React, { useEffect } from 'react';
import './SubmissionForm.css';
import { token } from './session.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faAngleDoubleRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { ButtonCaptureContinue } from './ButtonCaptureContinue';


function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type:mime});
}

class ResultsList extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <ul>
        {this.props.results.map((result) => (
          <li key={result} onClick={() => this.props.onSelect(result)}>
            {result}
          </li>
        ))}
      </ul>
    );
  }
}

export function submitDataWithCallbacks(api, formData, syncBufferEntryId, setSyncBuffer) {
  setSyncBuffer((prev) => {
    return prev.map((entry) => {
      if (entry.id === syncBufferEntryId) {
        entry.progress = 0;
        entry.failed = false;
      }
      return entry;
    });
  });


  api
    .post('/uploadfiles/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        setSyncBuffer((prev) => {
          return prev.map((entry) => {
            if (entry.id === syncBufferEntryId) {
              entry.progress = progressEvent.loaded / progressEvent.total;
              return entry;
            }
            return entry;
          });
        });
      }
    })
    .then(() => {
      setSyncBuffer((prev) => {
        return prev.filter((entry) => entry.id !== syncBufferEntryId);
      });
    })
    .catch(function (error) {
      setSyncBuffer((prev) => {
        return prev.map((entry) => {
          if (entry.id === syncBufferEntryId) {
            entry.failed = true;
          }
          return entry;
        });
      });
      console.error(error);
    });
}


export class SubmissionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      name: '', 
      side: '', 
      search_results: [], 
      value: '', 
      show_results: false, 
      show_add: false,
      handError: false,
      nameError: false,
      userInResults: false,
      max_value: 0,
      value: 0,
    };
    this.handleUsersResponse = this.handleUsersResponse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hanldeOptionSelect = this.hanldeOptionSelect.bind(this);
    this.handleAddNewUser = this.handleAddNewUser.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleHandError = this.handleHandError.bind(this);
    this.textInput = React.createRef();
  }

  handleUsersResponse(response) {
    const results = response.data.users;
    const userInResults = results.includes(this.state.name);

    this.setState((currentState) => ({
      ... currentState,
      search_results: results,
      userInResults: userInResults,
    }));
  }

  handleChange(event) {
    if (event.target.name === 'side') {
      this.setState(currentState => ({
        ... currentState,
        side: event.target.value,
        handError: false,
      }));
    }
    if (event.target.name === 'name') {
      var currentState = this.state
      this.setState(currentState => ({
        ... currentState,
        name: event.target.value,
        show_results: true,
        nameError: false,
      }));

      this.props.api
        .get('/search', {
          params: { user: event.target.value } 
        })
        .then(this.handleUsersResponse.bind(this))
        .catch(function (error) {
          console.error(error);
        });

        this.props.api
        .get('/canadduser', {
          params: { user: event.target.value} 
        })
        .then((() => { this.setState((state) =>({
          ...state,
          show_add: true 
        }))}).bind(this))
        .catch((() => this.setState((state) => ({
          ... state,
          show_add: false,
        }))).bind(this));
    }
  }

  handleProgress(progressEvent) {
    this.setState((currentState) => {
      currentState.max_value = progressEvent.total;
      currentState.value = progressEvent.loaded-1;
      return currentState;
    });
  }

  handleSuccess(response) {
    this.props.onSuccess();
  }

  handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', this.textInput.current.value);
    formData.append('side', this.state.side);
    formData.append('token', token);
    
    var err = false;
    if (!this.state.userInResults) {
      this.handleNameError();
      err = true;
    }
    if (this.props.imageList.length !== 4) {
      err = true;
    }
    if (this.textInput.current.value === '') {
      this.handleNameError();
      err = true;
    }
    if (this.state.side === '') {
      this.handleHandError();
      err = true;
    }

    if (err) {
      return;
    }


    for(const image of this.props.imageList) {
      formData.append('files', dataURLtoBlob(image), 'image.jpg');
    }

    const setSyncBuffer = this.props.setSyncBuffer;
    const syncBufferEntry = {
      id: Math.random(),
      name: this.textInput.current.value,
      side: this.state.side,
      formData: formData,
      failed: false,
      progress: 0,
    }

    setSyncBuffer((prev) => {
      return [...prev, syncBufferEntry];
    });

    submitDataWithCallbacks(this.props.api, formData, syncBufferEntry.id, setSyncBuffer);
    this.handleSuccess();
  }

  hanldeOptionSelect(name) {
    this.setState((currentState) => ({
      ... currentState,
      show_results: false,
      name: name,
      userInResults: true,
    }));
    this.textInput.current.value = name;
  }

  handleAddNewUser(event) {
    event.preventDefault();

    const formData = {
      'user': this.textInput.current.value
    }

    this.props.api
      .post('/adduser/', formData)
      .then((() => {this.hanldeOptionSelect(formData.user)}))
      .catch(function (error) {console.error(error);});
  }

  handleHandError() {
    this.setState((currentState) => ({
      ... currentState,
      handError: true,
    }));
  }

  handleNameError() {
    this.setState((currentState) => ({
      ... currentState,
      nameError: true,
    }));
  }

  render() {
    return (
      <form className='SubmissionForm' onSubmit={this.handleSubmit} onChange={this.handleChange}>
        <button onClick={this.props.handleBack} className='BackButton'><FontAwesomeIcon icon={faArrowLeft}/> Go back</button>
        <div className='SubmissionSideDiv' style={{color: (this.state.handError)?'red':'white'}}>
          <label className='SubmissionSideContainer'>
            <input type="radio" value="left" name="side" />
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="114" height="160" viewBox="-6 -6 120 155">
                <path 
                d="m 68.34526,117.95239 c 0.86302,-10.43863 17.597402,-20.76464 21.615218,-30.852241 3.005981,-7.547227 7.69046,-9.755148 9.374228,-14.237174 1.683794,-4.481996 2.529914,-7.868306 6.566454,-12.693237 1.0618,-1.269177 1.73069,-7.311292 -7.839018,-5.889634 C 88.492435,55.701701 79.431743,81.697496 74.28784,75.147613 62.417477,60.492083 67.988971,22.305597 67.180279,10.823081 66.672365,3.6113445 58.170304,3.7266015 57.00279,11.314195 55.486927,21.165609 52.092246,45.796886 51.977059,51.291224 51.333682,47.840053 52.666288,11.802471 53.048147,6.5031121 53.614892,-1.3621599 42.657661,-2.1880622 42.09545,6.3106889 41.813397,10.57397 38.063416,46.773469 37.913868,53.107475 37.994413,49.746981 34.261693,17.764553 33.954945,12.034925 33.543768,4.3545188 23.687644,5.005246 24.113099,13.19435 c 0.287936,3.877424 2.159606,34.322235 2.879502,39.635727 0.719869,5.313488 -4.751172,7.611162 -5.902973,4.451798 C 19.937827,54.122512 10.621218,34.804865 8.2758793,29.565621 6.4979919,25.594065 -1.1788405,27.888338 0.50123748,32.868612 3.1458057,40.707928 16.186153,66.601613 16.833023,86.721481 c 0.360908,11.225063 3.810986,15.519159 8.935399,31.521869 3.666004,11.44836 3.284511,16.76475 3.659442,31.28758 l 37.648523,0.20311 c 0.550216,-11.97265 0.256578,-19.53602 1.268873,-31.78164 z" 
                style={{
                    stroke: (this.state.handError)?'red':'white',
                    strokeWidth: '3px',
                    strokeOpacity: 0.6,
                    filter: "drop-shadow(0px 0px 5px rgb(0 0 0 / 0.7))"
                }}
                ></path>
              </svg>
            </span><br />Left hand
          </label>
          <label className='SubmissionSideContainer' ref={this.handInput}>
            <input type="radio" value="right" name="side" />
            <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="114" height="160" viewBox="-6 -6 120 155">
                <path 
                d="M 38.405055,117.95239 C 37.542035,107.51376 20.807653,97.18775 16.789837,87.100149 13.783856,79.552922 9.0993766,77.345001 7.4156086,72.862975 5.7318146,68.380979 4.8856946,64.994669 0.84915461,60.169738 c -1.0618,-1.269177 -1.73069,-7.311292 7.83901799,-5.889634 9.5697074,1.421597 18.6303994,27.417392 23.7743024,20.867509 11.870363,-14.65553 6.298869,-52.842016 7.107561,-64.324532 0.507914,-7.2117365 9.009975,-7.0964795 10.177489,0.491114 1.515863,9.851414 4.910544,34.482691 5.025731,39.977029 0.643377,-3.451171 -0.689229,-39.488753 -1.071088,-44.7881119 -0.566745,-7.865272 10.390486,-8.6911743 10.952697,-0.1924232 0.282053,4.2632811 4.032034,40.4627801 4.181582,46.7967861 -0.08055,-3.360494 3.652175,-35.342922 3.958923,-41.07255 0.411177,-7.6804062 10.267301,-7.029679 9.841846,1.159425 -0.287936,3.877424 -2.159606,34.322235 -2.879502,39.635727 -0.719869,5.313488 4.751172,7.611162 5.902973,4.451798 1.151801,-3.159363 10.46841,-22.47701 12.813748,-27.716254 1.777885,-3.971556 9.454725,-1.677283 7.774645,3.302991 -2.64457,7.839316 -15.684918,33.733001 -16.331788,53.852869 -0.360908,11.225063 -3.810986,15.519159 -8.935399,31.521869 -3.666004,11.44836 -3.284511,16.76475 -3.659442,31.28758 l -37.648523,0.20311 C 39.123712,137.76139 39.41735,130.19802 38.405055,117.9524 Z"
                style={{
                  stroke: (this.state.handError)?'red':'white',
                  strokeWidth: '3px',
                  strokeOpacity: 0.6,
                  filter: "drop-shadow(0px 0px 5px rgb(0 0 0 / 0.7))"
                }}
                ></path>
              </svg>
            </span><br />Right hand
          </label>
        </div>
        <div className='SubmissionNameContainer'>
          <label style={{...(this.state.nameError)?{border: 'solid 2px red'}:{} }}>
            <FontAwesomeIcon icon={faSearch} />
            <input type="text" name='name' placeholder="Enter your name ..." ref={this.textInput}/>
          </label>
          {
            (this.state.show_results)?(
              <>
              <ResultsList results={this.state.search_results} onSelect={this.hanldeOptionSelect}/>
              {this.state.show_add?<button onClick={this.handleAddNewUser} className='NewUser'>Add user {this.state.name}</button>:(<></>)}
              </>
            ):(<></>)
          }
        </div>
        <ButtonCaptureContinue
            value={this.state.value}
            max_value={this.state.max_value}
            size={92}
            thickness={8}
            space={3}
            continueCallback={()=>{}}
            captureCallback={()=>{}}
            fontSize={22}
            text={"Submit"}
            useTypeSubmit={true}
          ></ButtonCaptureContinue>
      </form>
    );
  }
}
